<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            label="사고년도"
            name="searchNm"
            type="year"
            default="today"
            v-model="searchParam.searchNm"
          />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="환경부 화학물질안전원 화학사고정보 목록"
      :columns="grid.columns"
      :data="grid.data"
      :isExcelDown="true"
      :usePaging="false"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline>
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>
<script>
export default {
  name: "apis-chem-accident",
  data() {
    return {
      grid: {
        columns: [
          {
            name: "cscDe",
            field: "cscDe",
            label: "사고일자",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "cscTy",
            field: "cscTy",
            label: "사고유형",
            align: "center",
            style: 'width:100px',
            sortable: true,
          },
          {
            name: "area",
            field: "area",
            label: "지역",
            style: 'width:150px',
            align: "center",
            sortable: true,
          },
          {
            name: "cause",
            field: "cause",
            style: 'width:200px',
            label: "사고원인",
            align: "left",
            sortable: true,
          },
          {
            name: "chem",
            field: "chem",
            style: 'width:200px',
            label: "사고물질",
            align: "center",
            sortable: true,
          },
          {
            name: "place",
            field: "place",
            style: 'width:150px',
            label: "발생장소",
            align: "center",
            sortable: true,
          },
          {
            name: "summary",
            field: "summary",
            type: 'html',
            label: "사고개요",
            align: "center",
            sortable: true,
          },
        ],
        data: [],
      },
      listUrl: '',
      searchParam: {
        searchNm: '',
        numOfRows: '99',
        pageNo: '1',
      },
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = '/api/openapi/apis/chemaccidents';
      // code setting
      // list setting
      this.getList()
    },
    getList() {
      if (this.searchParam.searchNm) {
        this.$http.url = this.listUrl;
        this.$http.type = 'GET';
        this.$http.param = this.searchParam;
        this.$http.request((_result) => {
          this.grid.data = _result.data.item
        },);
      }
    },
  },
};
</script>